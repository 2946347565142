import React from "react"

import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import BannerNav from "../banner-nav"

const AreYouDoctorBanner = props => {
  const { cms } = props
  return (
    <>
      <Box
        className="are-you-doctor-banner"
        component="section"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Container>
          <div className="banner-right">
            <h1>{cms.title}</h1>
            <p>{cms.content}</p>
            <BannerNav
              boxText={cms.link_bar.box_title}
              boxLink={cms.link_bar.box_link}
              boxClass="primary"
              circularText={cms.link_bar.circular_title}
              circularLink={cms.link_bar.circular_link}
            ></BannerNav>
          </div>
        </Container>
      </Box>
    </>
  )
}

export default AreYouDoctorBanner
