import React from "react"
import Fade from "react-reveal/Fade"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import BannerNav from "../banner-nav"
import { Check2 } from "react-bootstrap-icons"

import bannerLogo from "../../assets/what-you-get-banner.png"

const WhatYouGetBanner = props => {
  const { cms } = props
  return (
    <Box
      className="what-you-get-banner"
      component="section"
      display="flex"
      alignItems="center"
    >
      <Container>
        <Grid container>
          <Grid item className="banner-left" xs={12} lg={6}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <h1>{cms.title}</h1>
              <ul className="what-you-get-check-list">
                {cms.list.map((element, index) => {
                  return (
                    <li>
                      <Check2></Check2>
                      {element.content}
                    </li>
                  )
                })}
              </ul>
              <BannerNav
                boxText={cms.link_bar.box_title}
                boxLink={cms.link_bar.box_link}
                colorClass="white"
                circularText={cms.link_bar.circular_title}
                circularLink={cms.link_bar.circular_link}
              ></BannerNav>
            </Box>
          </Grid>
          <Grid item className="banner-right" xs={12} lg={6}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Fade fraction={0.5}>
                <img src={bannerLogo} alt="Carebits what you get banner"></img>
              </Fade>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default WhatYouGetBanner
