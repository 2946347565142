import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeBanner from "../sections/home/home-banner"
import WhatYouGetBanner from "../sections/home/what-you-get-banner"
import WhyChooseBanner from "../sections/home/why-choose-banner"
import MainMarketingBanner from "../sections/main-marketing-banner"
import AreYouDoctorBanner from "../sections/home/are-you-doctor-banner"

import { useIntl } from "gatsby-plugin-react-intl"

const IndexPage = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={data.strapiHome.seo.title}
        description={data.strapiHome.seo.description}
        keywords={data.strapiHome.seo.keywords}
      />
      <HomeBanner cms={data.strapiHome.homeBanner}></HomeBanner>
      <WhyChooseBanner
        cms={data.strapiHome.whyChooseCarebitsBanner}
      ></WhyChooseBanner>
      <WhatYouGetBanner
        cms={data.strapiHome.whatYouGetBanner}
      ></WhatYouGetBanner>
      <AreYouDoctorBanner
        cms={data.strapiHome.areYouDoctorBanner}
      ></AreYouDoctorBanner>
      <MainMarketingBanner
        cms={data.strapiMain.marketingBanner}
      ></MainMarketingBanner>
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiHome {
      id
      seo {
        title
        description
        keywords {
          tag
        }
      }
      homeBanner {
        title
        content
        link_bar {
          box_title
          box_link
          circular_title
          circular_link
        }
      }
      whyChooseCarebitsBanner {
        title
        accordion {
          title
          content
        }
        link_bar {
          box_title
          box_link
          circular_title
          circular_link
        }
      }
      whatYouGetBanner {
        title
        list {
          content
        }
        link_bar {
          box_title
          box_link
          circular_title
          circular_link
        }
      }
      areYouDoctorBanner {
        title
        content
        link_bar {
          box_title
          box_link
          circular_title
          circular_link
        }
      }
    }
    strapiMain {
      marketingBanner {
        title
        content
        link_bar {
          box_title
          box_link
          circular_title
          circular_link
        }
      }
    }
  }
`

export default IndexPage
