import React from "react"
import Fade from "react-reveal/Fade"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import BannerNav from "../banner-nav"
import CircularAccordion from "../../components/circularAccordion"

import bannerLogo from "../../assets/who-choose-carebits-banner.png"

const WhyChooseBanner = props => {
  const { cms } = props

  return (
    <>
      <section className="why-choose-banner">
        <Box display="flex" alignItems="center">
          <Container>
            <Grid container>
              <Grid item className="banner-left" xs={12} lg={7}>
                <Box display="flex" justifyContent="center">
                  <Fade fraction={0.75}>
                    <img
                      src={bannerLogo}
                      alt="Carebits why choose banner"
                    ></img>
                  </Fade>
                </Box>
              </Grid>
              <Grid item className="banner-right" xs={12} lg={5}>
                <Box display="flex" justifyContent="center">
                  <div>
                    <h1>{cms.title}</h1>
                    <CircularAccordion data={cms.accordion}></CircularAccordion>
                    <BannerNav
                      boxText={cms.link_bar.box_title}
                      boxLink={cms.link_bar.box_link}
                      boxClass="primary"
                      circularText={cms.link_bar.circular_title}
                      circularLink={cms.link_bar.circular_link}
                    ></BannerNav>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </section>
    </>
  )
}

export default WhyChooseBanner
